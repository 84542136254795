import { Button, Result } from 'antd'
import { navigate } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import i18n from '../config/i18n'


const SuccessPage = ({ location, pageContext: { locale } }: any) => (
  <Layout location={location}>
    <Result
      status="success"
      title={i18n[locale].success}
      subTitle={i18n[locale].successDesc}
      extra={
        <Button onClick={handleOnHomeClick} type="primary">
          {i18n[locale].backHome}
        </Button>
      }
    />
  </Layout>
)

function handleOnHomeClick() {
  navigate('/')
}

export default SuccessPage
